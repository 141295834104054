import React, { useEffect, useState } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Button, ButtonGroup, Input, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, Form, Label, Spinner, FormGroup
} from 'reactstrap';

import { FaPlus, FaEdit, FaClipboardList } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

const MachineView = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [machineData, setMachineData] = useState();
  const [currentData, setCurrentData] = useState();
  const [currentColumn, setCurrentColumn] = useState();
  const [activeButton, setActiveButton] = useState('Service');

  const { machine_id } = useParams();

  const navigate = useNavigate();

  const position = [22.8046, 86.2029];

  useEffect(() => {
    get_list_handler();
    setCurrentData(tableData1);
    setCurrentColumn(columns1);
    window.scrollTo(0, 0);
  }, []);

  const get_list_handler = () => {
    setLoading(true);

    const requestData = {
      machine_id: machine_id
    };

    axios.post(mainurl + 'master/getMachineDetails', requestData)
      .then(function (response) {
        // console.log(response.data);
        const machineDetails = response.data.data;
        setMachineData(machineDetails);
      })
      .catch(error => {
        console.log(error);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const labels = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const dataBar = {
    labels,
    datasets: [
      {
        label: 'Machine Utilization',
        data: [8, 2, 10, 0, 8, 8, 0],
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(255, 159, 64, 0.7)',
          'rgba(255, 205, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(201, 203, 207, 0.7)'],
      },
    ],
  };

  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

  const tableData1 = [
    {
      "id": 1,
      "date": '01-08-2024',
      "activity": '250 hrs',
      "cost": 'Rs. 2500.00',
      "remarks": 'Completed on schedule',
      action: 'Maintenance'
    },
    {
      id: 2,
      date: '15-09-2024',
      activity: '500 hrs',
      cost: 'Rs. 1200.00',
      remarks: 'No issues found',
      action: 'Maintenance'
    },
  ];

  // setCurrentData(tableData1);

  const tableData2 = [
    {
      id: 1,
      date: '20-08-2024',
      activity: 'Transmission Repair',
      cost: 'Rs. 8000.00',
      remarks: 'Resolved gearbox issue',
      action: 'Repair'
    },
    {
      id: 2,
      date: '22-08-2024',
      activity: 'Engine Overhaul',
      cost: 'Rs. 15000.00',
      remarks: 'Engine failure during operation',
      action: 'Repair'
    },
  ];

  const tableData3 = [
    {
      id: 1,
      date: '10-06-2024',
      activity: 'Hydraulic Leak Fix',
      cost: 'Rs. 3500.00',
      remarks: 'Leak detected during routine check',
      action: 'Other'
    },
    {
      id: 2,
      date: '12-07-2024',
      activity: 'Tire Replacement',
      cost: 'Rs. 5000.00',
      remarks: 'Tire burst unexpectedly',
      action: 'Other'
    },
  ];


  const columns1 = [
    {
      name: 'Sl No',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Date',
      selector: row => row.date,
    },
    {
      name: 'Milestone',
      selector: row => row.activity,
    },
    {
      name: 'Expenses',
      selector: row => row.cost,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
    },
    {
      name: 'Done By',
      selector: row => row.remarks,
    },
    {
      name: 'Action',
      selector: row => row.action,
      cell: row => <BsFillArrowRightSquareFill className='cursor' onClick={() => handleNavigate(row)} size={25} color="#ea130e" />,
    }
  ];

  const columns2 = [
    {
      name: 'Sl No',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Date',
      selector: row => row.date,
    },
    {
      name: 'Milestone',
      selector: row => row.activity,
    },
    {
      name: 'Expenses',
      selector: row => row.cost,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
    },
    {
      name: 'Done By',
      selector: row => row.remarks,
    },
    {
      name: 'Action',
      selector: row => row.action,
      cell: row => <BsFillArrowRightSquareFill className='cursor' onClick={() => handleNavigate(row)} size={25} color="#ea130e" />,
    }
  ];

  const columns3 = [
    {
      name: 'Sl No',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Date',
      selector: row => row.date,
    },
    {
      name: 'Milestone',
      selector: row => row.activity,
    },
    {
      name: 'Expenses',
      selector: row => row.cost,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
    },
    {
      name: 'Done By',
      selector: row => row.remarks,
    },
    {
      name: 'Action',
      selector: row => row.action,
      cell: row => <BsFillArrowRightSquareFill className='cursor' onClick={() => handleNavigate(row)} size={25} color="#ea130e" />,
    }
  ];

  const optionstack = {
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    }
  };

  const handleButtonClick = (buttonType) => {
    if (buttonType === 'Service') {
      setCurrentData(tableData1);
      setCurrentColumn(columns1);
    } else if (buttonType === 'Repair') {
      setCurrentData(tableData2);
      setCurrentColumn(columns2);
    } else if (buttonType === 'Other') {
      setCurrentData(tableData3);
      setCurrentColumn(columns3);
    }
    setActiveButton(buttonType);
  };

  const handleNavigate = (row) => {
    if (row.action === 'Maintenance') {
      navigate('/MaintainenceList');
    } else if (row.action === 'Repair') {
      navigate('/MaintainenceList');
    } else if (row.action === 'Other') {
      navigate('/MaintainenceList');
    }
  };


  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Machine Details" />
          </Col>
          <Col xs={9}>
            <Card color="primary" outline className='mb-3'>
              <CardHeader>Machine Information</CardHeader>
              <CardBody>
                <Row>
                  {machineData ? (
                    <>
                      <Label sm={3} > <b>Model: </b> </Label>
                      <Label sm={3} > {machineData?.model_header?.model ?? ''} </Label>

                      <Label sm={3} > <b>Registration Number: </b> </Label>
                      <Label sm={3} > {machineData?.name ?? ''} </Label>

                      <Label sm={3} > <b>Machine Type: </b> </Label>
                      <Label sm={3} > {machineData?.name ?? ''} </Label>

                      <Label sm={3} > <b>Engine Number: </b> </Label>
                      <Label sm={3} > {machineData?.serial_number ?? ''} </Label>

                      <Label sm={3} > <b>Serial Number:</b> </Label>
                      <Label sm={3} > {machineData?.serial_number ?? ''} </Label>

                      <Label sm={3} > <b>Transmission Serial No:</b> </Label>
                      <Label sm={3} > {machineData?.transmission_no ?? ''} </Label>

                      <Label sm={3} > <b>Status: </b> </Label>
                      <Label sm={3} > {machineData?.model_header?.model ?? ''} </Label>

                      <Label sm={3} > <b>Service Meter Unit(SMU): </b> </Label>
                      <Label sm={3}>
                        <Link to="/RunningList"> {0} </Link>
                      </Label>

                      <Label sm={3} > <b>Location: </b> </Label>
                      <Label sm={3} > {machineData?.location ?? ''} </Label>
                    </>
                  ) : (
                    <Label sm={12}>Loading machine information...</Label>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={3}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <MapContainer center={position} zoom={13} style={{ width: '100%', height: '200px' }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  // attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={position}>
                    <Popup>
                      <b>Machine Location</b> <br /> {machineData?.location ?? ''}
                    </Popup>
                  </Marker>
                </MapContainer>
              </CardBody>
            </Card>
          </Col>
          <Col xs={9}>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Other Information</CardHeader>
              <CardBody>
                <Row>
                  <Label sm={3} > <b>Date of Commissioning: </b> </Label>
                  <Label sm={3} > {machineData?.date_of_commissioning ?? ''} </Label>

                  <Label sm={3} > <b>Date of Purchase: </b> </Label>
                  <Label sm={3} > {machineData?.date_of_commissioning ?? ''} </Label>

                  <Label sm={3} > <b>Invoice Date: </b> </Label>
                  <Label sm={3} > Rs. {machineData?.invoice_value ?? ''} </Label>

                  <Label sm={3} > <b>Invoice Value: </b> </Label>
                  <Label sm={3} > Rs. {machineData?.invoice_value ?? ''} </Label>

                  <Label sm={3} > <b>Warranty: </b> </Label>
                  <Label sm={3} > 5 Years </Label>

                  <Label sm={3} > <b>Warranty Status: </b> </Label>
                  <Label sm={3} > Active </Label>

                  <Label sm={3} > <b>Total Km Run: </b> </Label>
                  <Label sm={3} > 42000 </Label>

                  {/* <Label sm={3} > <b>Next Maintenance: </b> </Label>
                  <Label sm={3} > 8 December 2024 </Label> */}

                  <hr />
                  <CardTitle className="text-primary">Maintenance Information</CardTitle>

                  <Label sm={3} > <b>Regular Maintenance: </b> </Label>
                  <Label sm={3} > Rs. 60000 (5) </Label>

                  <Label sm={3} > <b>Breakdown Maintainence: </b> </Label>
                  <Label sm={3} > Rs. 6000 (1) </Label>

                  <Label sm={3} > <b>Preventive Maintainence (Repair before Failure): </b> </Label>
                  <Label sm={3} > Rs. 12000 (2) </Label>

                  {/* <Label sm={3} > <b>Lubracant Cost: </b> </Label>
                  <Label sm={3} > Rs. 1500 </Label>

                  <Label sm={3} > <b>Fuel Cost: </b> </Label>
                  <Label sm={3} > Rs. 5000 </Label>

                  <Label sm={3} > <b>Electricity Cost: </b> </Label>
                  <Label sm={3} > Rs. 800 </Label>

                  <Label sm={3} > <b>Labour Cost: </b> </Label>
                  <Label sm={3} > Rs. 2400  </Label>

                  <Label sm={3} > <b>Breakdown Cost: </b> </Label>
                  <Label sm={3} > Rs. 9000  </Label>

                  <Label sm={3} > <b>MOR(Major Overhaul Cost): </b> </Label>
                  <Label sm={3} > Rs. 12400  </Label>

                  <Label sm={3} > <b>Running Maintenance Hrs: </b> </Label>
                  <Label sm={3} > Rs. 12000  </Label> */}

                  <hr />

                  <CardTitle className="text-primary">OEM Recommendation Overhaul</CardTitle>
                  <Col xs={12}>
                    <ul>
                      <li>If your equipment operates in a harsh or unusual environment, it may require more frequent maintenance than recommended by the OEM. For example, equipment operating in a dusty environment may require more frequent air filter changes.</li>
                      <li>If your equipment is used more frequently than the average user, it may require more frequent maintenance. Conversely, if the equipment is used less frequently than the average user, you may be able to reduce the frequency of maintenance.</li>

                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs={3}>

            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <img className='img-fluid' src={require('./../../assets/img/mac.jpg')} alt="Machine Img" />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Button className='text-white mb-3' color='primary' block  > <FaEdit /> Edit Machine Information  </Button>

            <Button className='text-white mb-3' color='primary' block  > <FaEdit /> Edit Other Information  </Button>

            <Button className='text-white mb-3' color='primary' block  > <FaEdit /> Edit Location Information </Button>

            <Link to="/CreateMaintainence"><Button className='text-white mb-3' color='primary' block  > <FaPlus /> ADD Service Maintenance Information  </Button></Link>

            <Link to="/CreateOtherMaintainence"><Button className='text-white mb-3' color='primary' block  > <FaPlus /> ADD Breakdown Maintenance Information  </Button></Link>

            <Link to="/CreateOtherMaintainence"><Button className='text-white mb-3' color='primary' block  > <FaPlus /> ADD Preventive Maintenance Information  </Button></Link>

            <Button className='text-white mb-3' color='primary' block  > <FaPlus /> ADD Output Details  </Button>

            <Link to="/CreateConsumption"><Button className='text-white mb-3' color='primary' block  > <FaPlus /> ADD Regular Consumption  </Button></Link>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Last Week Utilization</CardHeader>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <Line options={optionstack} data={dataBar} />
                  </Col>
                </Row>
              </CardBody>
            </Card>

          </Col>
          <Col xs={12}>
            <ButtonGroup className="mb-3 w-100">
              <Button color={activeButton === 'Service' ? 'primary' : 'light'} onClick={() => { handleButtonClick('Service'); setActiveButton('Service'); }}>Service Maintenance (Regular)</Button>
              <Button color={activeButton === 'Breakdown' ? 'primary' : 'light'} onClick={() => { handleButtonClick('Repair'); setActiveButton('Breakdown'); }}>Breakdown Repair</Button>
              <Button color={activeButton === 'Unplanned' ? 'primary' : 'light'} onClick={() => { handleButtonClick('Other'); setActiveButton('Unplanned'); }}>Preventive Maintenance</Button>
            </ButtonGroup>

            <DataTable
              columns={currentColumn}
              data={currentData}
              customStyles={custTableStyles}
            />
          </Col>
        </Row>
      </Page>

      <Footer />
    </div>
  )
}

export default MachineView;