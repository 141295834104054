import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, InputGroup, InputGroupText, Input
} from 'reactstrap';

import { FaFilePdf, FaEdit, FaClipboardList, FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from 'react-icons/md';

const MachineList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterPartData, setFilterPartData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
    get_filter_list_handler();
  }, []);

  const get_list_handler = () => {

    let list = [
      {
        "sl": 1,
        "Hours": '250',
        "Eng_Oil": '12',
        "Hyd_Oil": '',
        "Trans_Oil": '',
        "Axle_Oil": '',
        "Brake_Oil": '',
        "Coolant": '',
        "Rate": '154.00',
        "Cost": '1848.00',
      },
      {
        "sl": 2,
        "Hours": '500',
        "Eng_Oil": '12',
        "Hyd_Oil": '',
        "Trans_Oil": '',
        "Axle_Oil": '',
        "Brake_Oil": '',
        "Coolant": '',
        "Rate": '154.00',
        "Cost": '1848.00',
      },
      {
        "sl": 3,
        "Hours": '750',
        "Eng_Oil": '12',
        "Hyd_Oil": '',
        "Trans_Oil": '',
        "Axle_Oil": '',
        "Brake_Oil": '',
        "Coolant": '',
        "Rate": '154.00',
        "Cost": '1848.00',
      },
      {
        "sl": 4,
        "Hours": '1000',
        "Eng_Oil": '12',
        "Hyd_Oil": '',
        "Trans_Oil": '19',
        "Axle_Oil": '24',
        "Brake_Oil": '',
        "Coolant": '23',
        "Rate": '147.31',
        "Cost": '11490.00',
      }, {
        "sl": 5,
        "Hours": '1250',
        "Eng_Oil": '12',
        "Hyd_Oil": '',
        "Trans_Oil": '',
        "Axle_Oil": '',
        "Brake_Oil": '',
        "Coolant": '',
        "Rate": '154.00',
        "Cost": '1848.00',
      },
      {
        "sl": 6,
        "Hours": '1500',
        "Eng_Oil": '12',
        "Hyd_Oil": '',
        "Trans_Oil": '',
        "Axle_Oil": '',
        "Brake_Oil": '',
        "Coolant": '',
        "Rate": '154.00',
        "Cost": '1848.00',
      },
      {
        "sl": 7,
        "Hours": '1750',
        "Eng_Oil": '12',
        "Hyd_Oil": '',
        "Trans_Oil": '',
        "Axle_Oil": '',
        "Brake_Oil": '',
        "Coolant": '',
        "Rate": '154.00',
        "Cost": '1848.00',
      },
      {
        "sl": 8,
        "Hours": '2000',
        "Eng_Oil": '12',
        "Hyd_Oil": '100',
        "Trans_Oil": '19',
        "Axle_Oil": '24',
        "Brake_Oil": '1',
        "Coolant": '23',
        "Rate": '148.31',
        "Cost": '26512.00',
      },
    ]
    setTableData(list);
    setFilterData(list);
  }

  const get_filter_list_handler = () => {

    let list = [
      {
        "sl": 1,
        "Hours": '250',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '',
        "Air_Filter_Inner": '',
        "Trans_Filter": '',
        "Hyd_Filter": '',
        "Hyd_Breather": '',
        "Qty": '3',
        "Cost": '687.00',
      },
      {
        "sl": 2,
        "Hours": '500',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '1',
        "Air_Filter_Inner": '',
        "Trans_Filter": '1',
        "Hyd_Filter": '1',
        "Hyd_Breather": '1',
        "Qty": '7',
        "Cost": '3516.00',
      },
      {
        "sl": 3,
        "Hours": '750',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '',
        "Air_Filter_Inner": '',
        "Trans_Filter": '',
        "Hyd_Filter": '',
        "Hyd_Breather": '',
        "Qty": '3',
        "Cost": '687.00',
      },
      {
        "sl": 4,
        "Hours": '1000',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '1',
        "Air_Filter_Inner": '1',
        "Trans_Filter": '1',
        "Hyd_Filter": '1',
        "Hyd_Breather": '1',
        "Qty": '8',
        "Cost": '3860.00',
      },
      {
        "sl": 5,
        "Hours": '1250',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '',
        "Air_Filter_Inner": '',
        "Trans_Filter": '',
        "Hyd_Filter": '',
        "Hyd_Breather": '',
        "Qty": '3',
        "Cost": '687.00',
      },
      {
        "sl": 6,
        "Hours": '1500',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '1',
        "Air_Filter_Inner": '',
        "Trans_Filter": '1',
        "Hyd_Filter": '1',
        "Hyd_Breather": '1',
        "Qty": '7',
        "Cost": '3516.00',
      },
      {
        "sl": 7,
        "Hours": '1750',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '',
        "Air_Filter_Inner": '',
        "Trans_Filter": '',
        "Hyd_Filter": '',
        "Hyd_Breather": '',
        "Qty": '3',
        "Cost": '687.00',
      },
      {
        "sl": 8,
        "Hours": '2000',
        "Fuel_Filter": '1',
        "Water_Seperator": '1',
        "Engine_Oil_Filter": '1',
        "Air_Filter_Outer": '1',
        "Air_Filter_Inner": '1',
        "Trans_Filter": '1',
        "Hyd_Filter": '1',
        "Hyd_Breather": '1',
        "Qty": '8',
        "Cost": '3860.00',
      },
    ]
    setFilterPartData(list);
  }

  const filter_data_handler = (e) => {
    let filterData = tableData.filter(item => {
      const query = e.target.value.toLowerCase();
      console.log(query);
      return (
        item?.HospitalName?.toLowerCase().indexOf(query) >= 0 ||
        item?.Contact?.toLowerCase().indexOf(query) >= 0 ||
        item?.Address?.toLowerCase().indexOf(query) >= 0 ||
        item?.Email?.toLowerCase().indexOf(query) >= 0 ||
        item?.Gstin?.toLowerCase().indexOf(query) >= 0
      )
    })
    setFilterData(filterData);
  }

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={9} className='mb-1'>
            <Title pageTitle="Maintainence List" />
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        {/* <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={3} className='mb-2'>
                    <Button className='text-white' color='primary' size="sm" onClick={get_list_handler} > <FaClipboardList size={20} />  Get Machine List </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card> */}
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Title pageTitle="OIL" />
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Hours',
                      selector: row => row.Hours,
                    },
                    {
                      name: 'ENG. OIL 15 W 40',
                      selector: row => row.Eng_Oil,
                      wrap: true
                    },
                    {
                      name: 'HYD. OIL (HVI)',
                      selector: row => row.Hyd_Oil,
                      wrap: true,
                      // width: '150px'
                    },
                    {
                      name: 'TRANS.OIL (JCB ENGINE OIL)',
                      selector: row => row.Trans_Oil,
                      wrap: true
                    },
                    {
                      name: 'AXLE OIL (JCB GEAR OIL)',
                      selector: row => row.Axle_Oil,
                      wrap: true
                    },
                    {
                      name: 'BRAKE OIL (SERVO CASE BRAKE POWER)',
                      selector: row => row.Brake_Oil,
                      wrap: true
                    },
                    {
                      name: 'COOLANT (JCB READY TO USE)',
                      selector: row => row.Coolant,
                      wrap: true
                    },
                    // {
                    //   name: 'Rate/Ltr',
                    //   selector: row => row.Rate,
                    //   wrap: true
                    // },
                    {
                      name: 'Cost(in Rs.)',
                      selector: row => row.Cost,
                      wrap: true
                    },
                  ]}
                  data={tableData}
                // pagination
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Title pageTitle="FILTER" />
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Hours',
                      selector: row => row.Hours,
                    },
                    {
                      name: 'FUEL FILTER',
                      selector: row => row.Fuel_Filter,
                      wrap: true
                    },
                    {
                      name: 'WATER SEPERATOR',
                      selector: row => row.Water_Seperator,
                      wrap: true,
                      // width: '150px'
                    },
                    {
                      name: 'ENGINE OIL FILTER',
                      selector: row => row.Engine_Oil_Filter,
                      wrap: true
                    },
                    {
                      name: 'AIR FILTER OUTER',
                      selector: row => row.Air_Filter_Outer,
                      wrap: true
                    },
                    {
                      name: 'AIR FILTER INNER',
                      selector: row => row.Air_Filter_Inner,
                      wrap: true
                    },
                    {
                      name: 'TRANSMISSION FILTER (JCB READY TO USE)',
                      selector: row => row.Trans_Filter,
                      wrap: true
                    },
                    {
                      name: 'HYDRAULIC FILTER',
                      selector: row => row.Hyd_Filter,
                      wrap: true
                    },
                    {
                      name: 'HYDRAULIC BREATHER',
                      selector: row => row.Hyd_Breather,
                      wrap: true
                    },
                    // {
                    //   name: 'Total Qty',
                    //   selector: row => row.Qty,
                    //   wrap: true
                    // },
                    {
                      name: 'Cost(in Rs.)',
                      selector: row => row.Cost,
                      wrap: true
                    },
                  ]}
                  data={filterPartData}
                // pagination
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>

      <Footer />
    </div>
  )
}

export default MachineList;