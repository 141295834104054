import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';

import { Row, Col, Card, CardBody, Input, Button, Label, Spinner } from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

const CreateOtherMaintainence = () => {
  // redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  // State for the indent form
  const [indentNo, setIndentNo] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [indentDate, setIndentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [items, setItems] = useState([{ itemName: '', description: '' }]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddItem = () => {
    setItems([...items, { itemName: '', description: '' }]);
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleChangeItem = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleSubmit = () => {
    console.log('Submitting data:', { indentNo, indentDate, items });
    // Implement submit logic here
  };

  const [itemsData, setItemsData] = useState([
    {
      name: 'Engine Block',
      models: [
        { modelNo: 'TSEB1209', manufacturer: 'Tata Motors' },
        { modelNo: 'TSEB1210', manufacturer: 'Bharat Forge' },
      ],
    },
    {
      name: 'Air Filter',
      models: [
        { modelNo: 'BFAF9832', manufacturer: 'Bharat Forge' },
        { modelNo: 'AF1234', manufacturer: 'Tata Motors' },
      ],
    },
    {
      name: 'Transmission Gearbox',
      models: [
        { modelNo: 'MHTG4532', manufacturer: 'Mahindra & Mahindra' },
      ],
    },
    {
      name: 'Hydraulic Pump',
      models: [
        { modelNo: 'XYZH5029', manufacturer: 'Star Hydraulics' },
      ],
    },
  ]);

  const columns = [
    // {
    //   name: 'Manufacturer',
    //   selector: row => row.itemName,
    //   wrap: true,
    //   cell: (row, index) => (
    //     <Input
    //       placeholder="Manufacturer Name"
    //       type="text"
    //       value={row.itemName}
    //       onChange={(e) => handleChangeItem(index, 'itemName', e.target.value)}
    //     />
    //   )
    // },
    // {
    //   name: 'Model No',
    //   selector: row => row.itemName,
    //   wrap: true,
    //   cell: (row, index) => (
    //     <Input
    //       placeholder="Model No"
    //       type="text"
    //       value={row.itemName}
    //       onChange={(e) => handleChangeItem(index, 'itemName', e.target.value)}
    //     />
    //   )
    // },
    {
      name: 'Item Type',
      selector: row => row.itemName,
      wrap: true,
      cell: (row, index) => (

        <Input
          id="name"
          name="name"
          type="select"
          value={selectedItem}
          style={{ fontSize: '15px' }}
          required
        >
          <option value=""> Select Item Name </option>
          {itemsData.map(item => (
            <option key={item.name} value={item.name}>{item.name}</option>
          ))}
        </Input>
      )
    },
    {
      name: 'Part Number',
      selector: row => row.description,
      wrap: true,
      cell: (row, index) => (
        <Input
          placeholder="Part Number"
          type="text"
          value={row.description}
          onChange={(e) => handleChangeItem(index, 'description', e.target.value)}
        />
      )
    },
    {
      name: 'Quantity',
      selector: row => row.description,
      wrap: true,
      cell: (row, index) => (
        <Input
          placeholder="Quantity"
          type="number"
          value={row.description}
          onChange={(e) => handleChangeItem(index, 'description', e.target.value)}
        />
      )
    },
    {
      name: 'Unit Price',
      selector: row => row.description,
      wrap: true,
      cell: (row, index) => (
        <Input
          placeholder="Price"
          type="number"
          value={row.description}
          onChange={(e) => handleChangeItem(index, 'description', e.target.value)}
        />
      )
    },
    {
      name: 'Item Total',
      selector: row => row.description,
      wrap: true,
      cell: (row, index) => (
        <Input
          placeholder="Item Total"
          type="number"
          value={row.description}
          onChange={(e) => handleChangeItem(index, 'description', e.target.value)}
        />
      )
    },
    {
      name: 'Actions',
      wrap: true,
      width: "150px",
      cell: (row, index) => (
        <Button color="primary" onClick={() => handleRemoveItem(index)}>
          <MdDeleteForever size={20} /> Remove
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={9} className='mb-1'>
            <Title pageTitle="Create Breakdown Maintainence" />
          </Col>

          <Col md={3} className='text-end mb-3'>
            <Button className='text-white' color='primary' onClick={handleAddItem}>
              <FaPlusCircle size={20} /> Add Item
            </Button>
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Label>Machine Component</Label>
                    <Input
                      id="name"
                      name="name"
                      type="select"
                      required
                      // className="me-2"
                      style={{ fontSize: '14px' }}
                    >
                      <option value="250"> Breakdown Maintainence </option>
                      <option value="500"> Preventive Maintainence </option>
                    </Input>
                  </Col>
                  <Col md={2} className='mb-2'>
                    <Label>Maintainence Date</Label>
                    <Input
                      placeholder="Maintainence Date"
                      type="date"
                      value={indentDate}
                      onChange={(e) => setIndentDate(e.target.value)}
                    />
                  </Col>
                </Row>

                <DataTable
                  className='mb-3'
                  columns={columns}
                  data={items}
                  customStyles={custTableStyles}
                  noDataComponent={<div>No data available</div>}
                  progressPending={loading}

                />

                <Button className='mt-3' color='primary' onClick={handleSubmit}>
                  Save
                </Button>
                {/* <Button className='mt-3 mx-3' color='primary' onClick={handleSubmit}>
                  Approve
                </Button>
                <Button className='mt-3' color='primary' onClick={handleSubmit}>
                  Raise PO
                </Button> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Footer />
    </div>
  );
}

export default CreateOtherMaintainence;