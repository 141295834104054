import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import { confirm } from '../../component/utility/confirm';

import {
  Row, Col, Card, CardBody, Button, InputGroup, InputGroupText, Input
} from 'reactstrap';

import { FaFilePdf, FaEdit, FaClipboardList, FaSearch } from 'react-icons/fa';
import { BsFillArrowRightSquareFill } from "react-icons/bs";

import { MdDeleteForever } from 'react-icons/md';

const MachineList = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_list_handler();
  }, []);

  const get_list_handler = () => {
    setLoading(true);

    axios.get(mainurl + 'master/getMachineList')
      .then(function (response) {
        console.log(response);
        let list = response.data.data.map((row, index) => {
          return (
            {
              sl: index + 1,
              Machine_No: row?.reg_no ?? '',
              Make: row?.model_header?.manufacturer ?? '',
              Model: row?.model_header?.model ?? '',
              Owned_Rented: row?.owner_type ?? '',
              Location: row?.location ?? '',
              Action:
                <>
                  <BsFillArrowRightSquareFill className='cursor' onClick={() => navigate(`/MachineView/${row.machine_id}`)} size={25} color="#ea130e" />
                </>,
            }
          )
        })

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const filter_data_handler = (e) => {
    const query = e.target.value.toLowerCase();
    const filteredData = tableData.filter(item =>
      item.Machine_No.toLowerCase().includes(query) ||
      item.Make.toLowerCase().includes(query) ||
      item.Model.toLowerCase().includes(query) ||
      item.Location.toLowerCase().includes(query)
    );
    setFilterData(filteredData);
  };

  return (
    <div>
      <Header />
      <Page>
        <Col xs={12}>
          <div className='d-flex justify-content-between'>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Owned Machine</div>
              <div className='fs-20 text-primary'><b>15</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Rented Machine</div>
              <div className='fs-20 text-primary'><b>8</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Pollution Expiring</div>
              <div className='fs-20 text-primary'><b>20</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Insurance Expiring</div>
              <div className='fs-20 text-primary'><b>2</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Road Tax Expiring</div>
              <div className='fs-20 text-primary'><b>20</b></div>
            </div>
          </div>
        </Col>
        <Col xs={12} className="mb-3 " >
          <div className='d-flex justify-content-between'>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Service Expiry</div>
              <div className='fs-20 text-primary'><b>4</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>OEM Warranty Expiry</div>
              <div className='fs-20 text-primary'><b>1</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Under Maintainence</div>
              <div className='fs-20 text-primary'><b>3</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Idle Machines</div>
              <div className='fs-20 text-primary'><b>7</b></div>
            </div>
            <div className='bBox'>
              <div className='text-muted fs-bold'>Running Machines</div>
              <div className='fs-20 text-primary'><b>13</b></div>
            </div>
          </div>
        </Col>
        <Row className='mb-3'>
          <Col xs={9} className='mb-1'>
            <Title pageTitle="Machine List" />
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        {/* <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={3} className='mb-2'>
                    <Button className='text-white' color='primary' size="sm" onClick={get_list_handler} > <FaClipboardList size={20} />  Get Machine List </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card> */}
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-3'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Reg No',
                      selector: row => row.Machine_No,
                    },
                    {
                      name: 'Model',
                      selector: row => row.Model,
                      wrap: true,
                    },
                    {
                      name: 'Type',
                      selector: row => row.Make,
                      wrap: true
                    },
                    {
                      name: 'Own/Rented',
                      selector: row => row.Owned_Rented,
                      wrap: true
                    },
                    {
                      name: 'Running Hours',
                      selector: row => row.Owned_Rented,
                      wrap: true
                    },
                    {
                      name: 'Location',
                      selector: row => row.Location,
                      wrap: true
                    },
                    {
                      name: 'Status',
                      selector: row => row.Location,
                      wrap: true
                    },
                    {
                      name: 'Action',
                      selector: row => row.Action,
                    },
                  ]}
                  data={filterData}
                  pagination
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>

      <Footer />
    </div>
  )
}

export default MachineList;