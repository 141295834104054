import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '../component/hocs/notification';

import { useDispatch } from 'react-redux';
import { signIn } from './../component/redux/logged';

import axios from 'axios';
import { mainurl } from '../MainUrl';

import { Col, Row, Label, Input, Button, FormGroup, } from 'reactstrap';

import logo from './../assets/img/logo.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState('Admin');
  const [location, setLocation] = useState('Ranchi');

  const user_login = 'admin';
  const user_pass = 'admin123';

  const login_handler = () => {
    let postData = {
      "user_id": userName,
      "password": password,
    };
    //
    if (userName === user_login && password === user_pass) {

      dispatch(signIn({
        login: true,
        userName: user_login,
        userId: 123,
        role: role,
        location: location,
        logData: ''
      }));
      navigate('/home');
      let notify = notification({ message: 'Login successful!', type: 'success' });
      notify();
    } else {
      let notify = notification({ message: 'Invalid login credentials!', type: 'error' });
      notify();
    }
    /*axios({
      method: 'post',
      url: mainurl + 'master/userLogin',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
  })
      .then(function (response) {
          if(response.data.status > 0){
            dispatch(signIn({
              login: true,
              userName: response.data.data.user_name,
              userId: response.data.data.user_code,
              logData: response.data.data
            }));
 
            let notify = notification({ message: response.data.message, type: 'success' });
            notify();
            
            
            navigate('/home');
            setLoading(false);
 
          }else{
            setLoading(false);
            let notify = notification({ message: response.data.message, type: 'error' });
            notify();
          }
          
 
      }).catch(error => {
          setLoading(false);
          let notify = notification({ message: "User id and password not matched! Try Again", type: 'error' });
          notify();
      })*/

    //
  }

  return (
    <Row className="d-flex justify-content-end align-items-center br-5 loginPage">
      <Col md={4} className="h-100">
        <Row className="h-100">
          <Col md={12} className="h-100" style={{ backgroundColor: 'rgba(255,255,255,0.9)', boxShadow: 'rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset' }}>
            <Row style={{ padding: '20px', marginTop: '60px' }}>
              <Col md={12} className="mb-4">
                <div className="text-center mb-3">
                  <img height="170px" src={logo} alt="logo" />
                </div>
              </Col>

              <Col md={12} className="mb-2">
                <FormGroup row>
                  <Label sm={4}>User Name</Label>
                  <Col sm={8} className="mb-1">
                    <Input type="text" bsSize="sm" value={userName} onChange={(e) => setUserName(e.target.value)} required />
                  </Col>
                </FormGroup>
              </Col>

              <Col md={12} className="mb-2">
                <FormGroup row>
                  <Label sm={4}>Password</Label>
                  <Col sm={8} className="mb-1">
                    <Input type="password" bsSize="sm" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </Col>
                </FormGroup>
              </Col>

              <Col md={12} className="mb-2">
                <FormGroup row>
                  <Label sm={4}>Role</Label>
                  <Col sm={8} className="mb-1">
                    <Input type="select" bsSize="sm" value={role} onChange={(e) => setRole(e.target.value)}>
                      <option>Admin</option>
                      <option>User</option>
                      <option>Manager</option>
                    </Input>
                  </Col>
                </FormGroup>
              </Col>

              <Col md={12} className="mb-2">
                <FormGroup row>
                  <Label sm={4}>Location</Label>
                  <Col sm={8} className="mb-1">
                    <Input type="select" bsSize="sm" value={location} onChange={(e) => setLocation(e.target.value)}>
                      <option>Ranchi</option>
                      <option>Jamshedpur</option>
                      <option>Kolkata</option>
                    </Input>
                  </Col>
                </FormGroup>
              </Col>

              <Col md={12} className="mb-3">
                <Button className="text-white" color="primary" block onClick={login_handler}>Login</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Login;
