
import { Routes, Route } from "react-router-dom";

import WIP from './pages/WIP';
import './style/main.scss';

import Login from "./pages/Login";
import Home from './pages/Home';

import MachineList from './pages/demo/MachineList';
import MachineView from './pages/demo/MachineView';
import ManpowerList from './pages/demo/ManpowerList';
import ProjectList from './pages/demo/ProjectList';
import ProjectView from './pages/demo/ProjectView';
import StoreList from './pages/demo/StoreList';
import IndentList from './pages/demo/IndentList';
import IndentView from './pages/demo/IndentView';
import POList from './pages/demo/POList';
import POView from './pages/demo/POView';

import StoreReport from './pages/demo/StoreReport';

import StoreMaster from './pages/master/StoreMaster';
import PartMaster from './pages/master/PartMaster';
import SiteMaster from './pages/master/SiteMaster';

import CreateIndent from './pages/create/CreateIndent';
import CreateMaintainence from './pages/create/CreateMaintainence';
import CreateConsumption from './pages/create/CreateConsumption';

import CreateOtherMaintainence from './pages/create/CreateOtherMaintainence';
import MaintainenceList from './pages/demo/MaintainenceList';
import RunningList from './pages/demo/RunningList';

function App() {
  return (
    <div className="App" >
      <Routes>
        <Route path="*" element={<WIP />} />
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />

        <Route path="/MachineList" element={<MachineList />} />
        <Route path="/MachineView/:machine_id" element={<MachineView />} />
        <Route path="/ManpowerList" element={<ManpowerList />} />
        <Route path="/ProjectList" element={<ProjectList />} />
        <Route path="/ProjectView" element={<ProjectView />} />
        <Route path="/StoreList" element={<StoreList />} />
        <Route path="/IndentList" element={<IndentList />} />
        <Route path="/IndentView" element={<IndentView />} />
        <Route path="/POList" element={<POList />} />
        <Route path="/POView" element={<POView />} />

        <Route path="/RunningList" element={<RunningList />} />

        <Route path="/StoreReport" element={<StoreReport />} />

        <Route path="/StoreMaster" element={<StoreMaster />} />
        <Route path="/PartMaster" element={<PartMaster />} />
        <Route path="/SiteMaster" element={<SiteMaster />} />

        <Route path="/CreateIndent" element={<CreateIndent />} />
        <Route path="/CreateMaintainence" element={<CreateMaintainence />} />
        <Route path="/CreateConsumption" element={<CreateConsumption />} />

        <Route path="/CreateOtherMaintainence" element={<CreateOtherMaintainence />} />

        <Route path="/MaintainenceList" element={<MaintainenceList />} />

      </Routes>
    </div>
  );
}

export default App;