import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import { Row, Col, Card, CardBody, Input, Button, Label, Spinner } from 'reactstrap';

import { FaUpload, FaCheckCircle } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

const CreateMaintainence = () => {
  // redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);

  // State for the indent form
  const [indentNo, setIndentNo] = useState('');
  const [indentDate, setIndentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [oilItems, setOilItems] = useState([{ hours: '', oil15w40: 'No', hviOil: 'No', transOil: 'No', axleOil: 'No', brakeOil: 'No', coolant: 'No', oil15w40Qty: '', hviOilQty: '', transOilQty: '', axleOilQty: '', brakeOilQty: '', coolantQty: '', rate: '', cost: '' }]);
  const [filterItems, setFilterItems] = useState([{ hours: '', fuelFilter: '', waterSeperator: '', engOilFilter: '', airFilterOuter: '', airFilterInner: '', transFilter: '', hydFilter: '', hydBreather: '', qty: '', cost: '' }]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const handleAddOilItem = () => {
    setOilItems([...oilItems, { hours: '', oil15w40: 'No', hviOil: 'No', transOil: 'No', axleOil: 'No', brakeOil: 'No', coolant: 'No', oil15w40Qty: '', hviOilQty: '', transOilQty: '', axleOilQty: '', brakeOilQty: '', coolantQty: '', rate: '', cost: '' }]);
  };

  const handleRemoveOilItem = (index) => {
    const newOilItems = oilItems.filter((_, i) => i !== index);
    setOilItems(newOilItems);
  };

  const handleChangeOilItem = (index, field, value) => {
    const updatedRows = [...oilItems];
    const positiveValue = Math.max(0, parseFloat(value) || 0);

    // if (field === 'hours') {
    //   updatedRows[index][field] = Math.max(250, Math.ceil(positiveValue / 250) * 250);
    // } else {
    // updatedRows[index][field] = positiveValue;
    // }

    if (['oil15w40', 'hviOil', 'transOil', 'axleOil', 'brakeOil', 'coolant'].includes(field)) {
      updatedRows[index][field] = value;
    } else {
      const positiveValue = Math.max(0, parseFloat(value) || 0);
      updatedRows[index][field] = positiveValue;
    }

    // updatedRows[index][field] = value;

    const litersFields = ['oil15w40Qty', 'hviOilQty', 'transOilQty', 'axleOilQty', 'brakeOilQty', 'coolantQty'];
    const totalLiters = litersFields.reduce((sum, key) => {
      return sum + (parseFloat(updatedRows[index][key]) || 0);
    }, 0);

    // Calculate cost based on total liters and rate per liter
    const rate = parseFloat(updatedRows[index].rate) || 0;
    const cost = totalLiters * rate;

    // Update the cost in the row
    updatedRows[index].cost = cost;

    setOilItems(updatedRows);
  };



  const handleAddFilterItem = () => {
    setFilterItems([...filterItems, { hours: '', fuelFilter: '', waterSeperator: '', engOilFilter: '', airFilterOuter: '', airFilterInner: '', transFilter: '', hydFilter: '', hydBreather: '', qty: '', cost: '' }]);
  };

  const handleRemoveFilterItem = (index) => {
    const newFilterItems = filterItems.filter((_, i) => i !== index);
    setFilterItems(newFilterItems);
  };

  const handleChangeFilterItem = (index, field, value) => {
    const updatedRows = [...oilItems];
    const positiveValue = Math.max(0, parseFloat(value) || 0);

    // if (field === 'hours') {
    //   updatedRows[index][field] = Math.max(250, Math.ceil(positiveValue / 250) * 250);
    // } else {
    // updatedRows[index][field] = positiveValue;
    // }

    if (['fuelFilter', 'waterSeperator', 'engOilFilter', 'airFilterOuter', 'airFilterInner', 'transFilter', 'hydFilter', 'hydBreather'].includes(field)) {
      updatedRows[index][field] = value;
    } else {
      const positiveValue = Math.max(0, parseFloat(value) || 0);
      updatedRows[index][field] = positiveValue;
    }

    // updatedRows[index][field] = value;

    const litersFields = ['fuelFilterQty', 'waterSeperatorQty', 'engOilFilterQty', 'airFilterOuterQty', 'airFilterInnerQty', 'transFilterQty', 'hydFilterQty', 'hydBreatherQty'];
    const totalLiters = litersFields.reduce((sum, key) => {
      return sum + (parseFloat(updatedRows[index][key]) || 0);
    }, 0);

    // Update the qty in the row
    updatedRows[index].qty = totalLiters;

    setFilterItems(updatedRows);
  };

  const handleSubmit = () => {
    console.log('Submitting data:', { indentNo, indentDate, oilItems, filterItems });
    // Implement submit logic here
  };

  const handleImageUpload = (index, fieldName, file) => {
    console.log(`Image uploaded for ${fieldName} at index ${index}`);
  };

  const oilColumns = [
    // {
    //   name: 'Hours',
    //   selector: row => row.hours,
    //   wrap: true,
    //   cell: (row, index) => (
    //     <div className="d-flex">
    //       <Input
    //         placeholder="Hours"
    //         type="number"
    //         value={row.hours}
    //         onChange={(e) => handleChangeOilItem(index, 'hours', e.target.value)}
    //         style={{ width: '90%' }}
    //         min="250"
    //       />
    //     </div>
    //   ),
    // },
    {
      name: 'ENG. OIL 15 W 40',
      selector: row => row.oil15w40,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <input
            type="checkbox"
            checked={row.oil15w40 === 'Yes'}
            onChange={(e) => handleChangeOilItem(index, 'oil15w40', e.target.checked ? 'Yes' : 'No')}
          // style={{ width: '20px', height: '20px' }}
          />
          {row.oil15w40 === 'Yes' && (
            <Input
              placeholder="Part No"
              type="number"
              value={row.oil15w40Qty || ''}
              onChange={(e) => handleChangeOilItem(index, 'oil15w40Qty', e.target.value)}
              style={{ width: '40%', marginLeft: '5px' }}
              min="0"
            />
          )}
        </div>
      ),
    },
    {
      name: 'HYD. OIL (HVI)',
      selector: row => row.hviOil,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <input
            type="checkbox"
            checked={row.hviOil === 'Yes'}
            onChange={(e) => handleChangeOilItem(index, 'hviOil', e.target.checked ? 'Yes' : 'No')}
          // style={{ width: '20px', height: '20px' }}
          />
          {row.hviOil === 'Yes' && (
            <Input
              placeholder="Part No"
              type="number"
              value={row.hviOilQty || ''}
              onChange={(e) => handleChangeOilItem(index, 'hviOilQty', e.target.value)}
              style={{ width: '40%', marginLeft: '5px' }}
              min="0"
            />
          )}
        </div>
      ),
    },
    {
      name: 'TRANS. OIL (JCB ENGINE OIL)',
      selector: row => row.transOil,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <input
            type="checkbox"
            checked={row.transOil === 'Yes'}
            onChange={(e) => handleChangeOilItem(index, 'transOil', e.target.checked ? 'Yes' : 'No')}
          // style={{ width: '20px', height: '20px' }}
          />
          {row.transOil === 'Yes' && (
            <Input
              placeholder="Part No"
              type="number"
              value={row.transOilQty || ''}
              onChange={(e) => handleChangeOilItem(index, 'transOilQty', e.target.value)}
              style={{ width: '40%', marginLeft: '5px' }}
              min="0"
            />
          )}
        </div>
      ),
    },
    {
      name: 'AXLE. OIL (JCB GEAR OIL)',
      selector: row => row.axleOil,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <input
            type="checkbox"
            checked={row.axleOil === 'Yes'}
            onChange={(e) => handleChangeOilItem(index, 'axleOil', e.target.checked ? 'Yes' : 'No')}
          // style={{ width: '20px', height: '20px' }}
          />
          {row.axleOil === 'Yes' && (
            <Input
              placeholder="Part No"
              type="number"
              value={row.axleOilQty || ''}
              onChange={(e) => handleChangeOilItem(index, 'axleOilQty', e.target.value)}
              style={{ width: '40%', marginLeft: '5px' }}
              min="0"
            />
          )}
        </div>
      ),
    },
    {
      name: 'BRAKE. OIL (SERVO CASE BRAKE POWER)',
      selector: row => row.brakeOil,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <input
            type="checkbox"
            checked={row.brakeOil === 'Yes'}
            onChange={(e) => handleChangeOilItem(index, 'brakeOil', e.target.checked ? 'Yes' : 'No')}
          // style={{ width: '20px', height: '20px' }}
          />
          {row.brakeOil === 'Yes' && (
            <Input
              placeholder="Part No"
              type="number"
              value={row.brakeOilQty || ''}
              onChange={(e) => handleChangeOilItem(index, 'brakeOilQty', e.target.value)}
              style={{ width: '40%', marginLeft: '5px' }}
              min="0"
            />
          )}
        </div>
      ),
    },
    {
      name: 'COOLANT (JCB READY TO USE)',
      selector: row => row.coolant,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <input
            type="checkbox"
            checked={row.coolant === 'Yes'}
            onChange={(e) => handleChangeOilItem(index, 'coolant', e.target.checked ? 'Yes' : 'No')}
          // style={{ width: '20px', height: '20px' }}
          />
          {row.coolant === 'Yes' && (
            <Input
              placeholder="Part No"
              type="number"
              value={row.coolantQty || ''}
              onChange={(e) => handleChangeOilItem(index, 'coolantQty', e.target.value)}
              style={{ width: '40%', marginLeft: '5px' }}
              min="0"
            />
          )}
        </div>
      ),
    },
    // {
    //   name: 'RATE / LTR',
    //   selector: row => row.rate,
    //   wrap: true,
    //   cell: (row, index) => (
    //     <div className="d-flex">
    //       <Input
    //         placeholder="Rate/Ltr"
    //         type="number"
    //         value={row.rate}
    //         onChange={(e) => handleChangeOilItem(index, 'rate', e.target.value)}
    //         style={{ width: '90%' }}
    //         min="0"
    //       />
    //     </div>
    //   ),
    // },
    {
      name: 'COST (in Rs.)',
      selector: row => row.cost,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <Input
            placeholder="Amount"
            type="number"
            value={row.cost}
            onChange={(e) => handleChangeOilItem(index, 'cost', e.target.value)}
            style={{ width: '90%' }}
            readOnly
          />
        </div>
      ),
    },
    // {
    //   name: 'Actions',
    //   wrap: true,
    //   width: "150px",
    //   cell: (row, index) => (
    //     <Button color="primary" onClick={() => handleRemoveOilItem(index)}>
    //       <MdDeleteForever size={20} /> Remove
    //     </Button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // }
  ];



  const filterColumns = [
    {
      name: 'FUEL FILTER',
      selector: row => row.fuelFilter,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.fuelFilter === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'fuelFilter', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'fuelFilter', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    {
      name: 'WATER SEPERATOR',
      selector: row => row.waterSeperator,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.waterSeperator === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'waterSeperator', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'waterSeperator', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    {
      name: 'ENGINE OIL FILTER',
      selector: row => row.engOilFilter,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.engOilFilter === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'engOilFilter', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'engOilFilter', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    {
      name: 'AIR FILTER OUTER',
      selector: row => row.airFilterOuter,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.airFilterOuter === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'airFilterOuter', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'airFilterOuter', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    {
      name: 'AIR FILTER INNER',
      selector: row => row.airFilterInner,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.airFilterInner === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'airFilterInner', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'airFilterInner', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    {
      name: 'TRANSMISSION FILTER (JCB READY TO USE)',
      selector: row => row.transFilter,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.transFilter === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'transFilter', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'transFilter', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    {
      name: 'HYDRAULIC FILTER',
      selector: row => row.hydFilter,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.hydFilter === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'hydFilter', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'hydFilter', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    {
      name: 'HYDRAULIC BREATHER',
      selector: row => row.hydBreather,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={row.hydBreather === 'Yes'}
            onChange={(e) => handleChangeFilterItem(index, 'hydBreather', e.target.checked ? 'Yes' : 'No')}
            style={{ marginRight: '10px' }}
          />
          <button
            onClick={() => document.getElementById(`imageUploadInput${index}`).click()}
            style={{ width: '90%' }}
          >
            Upload
          </button>
          <input
            type="file"
            accept="image/*"
            id={`imageUploadInput${index}`}
            onChange={(e) => handleImageUpload(index, 'hydBreather', e.target.files[0])}
            style={{ display: 'none' }}
          />
        </div>
      ),
    },
    // {
    //   name: 'QUANTITY',
    //   selector: row => row.qty,
    //   wrap: true,
    //   cell: (row, index) => (
    //     <div className="d-flex">
    //       <Input
    //         placeholder="Quantity"
    //         type="number"
    //         value={row.qty}
    //         onChange={(e) => handleChangeFilterItem(index, 'qty', e.target.value)}
    //         style={{ width: '90%' }}
    //         readOnly
    //       />
    //     </div>
    //   ),
    // },
    {
      name: 'COST (in Rs.)',
      selector: row => row.cost,
      wrap: true,
      cell: (row, index) => (
        <div className="d-flex">
          <Input
            placeholder="Amount"
            type="number"
            value={row.cost}
            onChange={(e) => handleChangeFilterItem(index, 'cost', e.target.value)}
            style={{ width: '90%' }}
            readOnly
          />
        </div>
      ),
    },
    // {
    //   name: 'Actions',
    //   wrap: true,
    //   width: "150px",
    //   cell: (row, index) => (
    //     <Button color="primary" onClick={() => handleRemoveFilterItem(index)}>
    //       <MdDeleteForever size={20} /> Remove
    //     </Button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // }
  ];

  return (
    <div>
      <Header />
      <Page>
        <Row className='mb-3'>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Create Service Maintainence Information" />
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={9} className='mb-1'>
                    <Title pageTitle="OIL" />
                  </Col>
                  {/* <Col md={3} className='text-end mb-1'>
                    <Button className='text-white' color='primary' onClick={handleAddOilItem}>
                      <FaPlusCircle size={20} /> Add Item
                    </Button>
                  </Col> */}
                </Row>
                <Row className='my-2'>
                  <Col md={4}>
                    <Label>Hours</Label>
                    <Input
                      id="name"
                      name="name"
                      type="select"
                      required
                      // className="me-2"
                      // style={{ fontSize: '14px' }}
                      readonly
                    >
                      <option value="250"> 250 </option>
                      <option value="500"> 500 </option>
                      <option value="750"> 750 </option>
                      <option value="1000"> 1000 </option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>Maintainence Date</Label>
                    <Input
                      placeholder="Maintainence Date"
                      type="date"
                      value={indentDate}
                      onChange={(e) => setIndentDate(e.target.value)}
                    />
                  </Col>
                </Row>

                <DataTable
                  className='mb-3'
                  columns={oilColumns}
                  data={oilItems}
                  customStyles={custTableStyles}
                  noDataComponent={<div>No data available</div>}
                  progressPending={loading}

                />

                {/* <Button color='primary' onClick={handleSubmit}>
                  Save
                </Button> */}
                {/* <Button className='mt-3 mx-3' color='primary' onClick={handleSubmit}>
                  Approve
                </Button>
                <Button className='mt-3' color='primary' onClick={handleSubmit}>
                  Raise PO
                </Button> */}
                {/* </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className='mb-3'>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardBody> */}
                <br />
                <br />
                <br />
                <Row>
                  <Col md={9} className='mb-1'>
                    <Title pageTitle="FILTER" />
                  </Col>
                  {/* <Col md={3} className='text-end mb-1'>
                    <Button className='text-white' color='primary' onClick={handleAddFilterItem}>
                      <FaPlusCircle size={20} /> Add Item
                    </Button>
                  </Col> */}
                </Row>

                <DataTable
                  className='mb-3'
                  columns={filterColumns}
                  data={filterItems}
                  customStyles={custTableStyles}
                  noDataComponent={<div>No data available</div>}
                  progressPending={loading}

                />

                <Button color='primary' onClick={handleSubmit}>
                  Save
                </Button>
                {/* <Button className='mt-3 mx-3' color='primary' onClick={handleSubmit}>
                  Approve
                </Button>
                <Button className='mt-3' color='primary' onClick={handleSubmit}>
                  Raise PO
                </Button> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Footer />
    </div>
  );
}

export default CreateMaintainence;